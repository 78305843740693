import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import { compose } from 'redux'
import { logout } from './actions'

const Logout = ({ onLogout }) => {
  onLogout()
  return (
    <div>
      <LinearProgress />
    </div>
  )
}

Logout.propTypes = {
  onLogout: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onLogout: () => dispatch(logout()),
  }
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(Logout)
