import { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Check from '@material-ui/icons/Check'
import Edit from '@material-ui/icons/Edit'

import TextField from 'components/TextField'
import CustomSelectField from 'components/CustomSelectField'
import CustomSwitch from 'components/CustomSwitch'
import Button from 'components/Button'

import { useSnackbar } from 'notistack'
import useStyles from '../style'

const renderButtonName = (tabIndex, className) => `Seed ${tabIndex === 0 ? 'students' : 'teachers'} of ${className}`

const ClassDetail = memo(
  ({
    classDetails,
    subjectList,
    initialSubject,
    disableSeedButton,
    currentTab,
    studentsLength,
    teachersLength,
    onChange,
    onSeedButtonClick,
  }) => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const [state, setState] = useState({
      className: '',
      classDisplayName: '',
      isFreemium: false,
      editClassName: false,
      editDisplayName: false,
      confirmEditClassName: false,
      confirmEditClassDisplayName: false,
      errorMessage: '',
    })

    useEffect(() => {
      if (state.errorMessage) {
        enqueueSnackbar(state.errorMessage, {
          variant: 'error',
          onClose: () => {
            setState((prevState) => ({
              ...prevState,
              errorMessage: '',
            }))
          },
        })
      }
    }, [enqueueSnackbar, state.errorMessage])

    useEffect(() => {
      setState((prevState) => ({
        ...prevState,
        className: classDetails.name,
        classDisplayName: classDetails.displayName,
        isFreemium: classDetails.isFreemium,
        errorMessage: '',
      }))
    }, [classDetails])

    const handleClassDetailFieldChange = (id, value) => {
      setState((prevState) => ({
        ...prevState,
        [id]: value,
      }))
    }

    return (
      <div style={{ padding: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              id='className'
              label='Class Name'
              value={state.className}
              disabled={!state.editClassName}
              handleChange={(val) => handleClassDetailFieldChange('className', val)}
              fullWidth
              required
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='update-class-name'
                    onClick={() => {
                      if (state.editClassName) {
                        onChange('className', state.className)
                      }
                      setState((prevState) => ({
                        ...prevState,
                        editClassName: !prevState.editClassName,
                      }))
                    }}
                    edge='end'
                  >
                    {state.editClassName ? <Check /> : <Edit />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id='classDisplayName'
              label='Class Display Name'
              value={state.classDisplayName}
              disabled={!state.editDisplayName}
              handleChange={(val) => handleClassDetailFieldChange('classDisplayName', val)}
              fullWidth
              required
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='update-display-name'
                    onClick={() => {
                      if (state.editDisplayName) {
                        onChange('displayName', state.classDisplayName)
                      }

                      setState((prevState) => ({
                        ...prevState,
                        editDisplayName: !prevState.editDisplayName,
                      }))
                    }}
                    edge='end'
                  >
                    {state.editDisplayName ? <Check /> : <Edit />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={4} style={{ display: 'inline-flex', justifyContent: 'center' }}>
            <CustomSwitch
              id='isFreemium'
              label='Is Freemium?'
              checked={state.isFreemium}
              onChange={(id, val) => {
                handleClassDetailFieldChange(id, val)
                onChange('isFreemium', val)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6' className={classes.paper} style={{ textAlign: 'left' }}>
              {`Total ${currentTab === 0 ? `Students:  ${studentsLength}` : `Teachers:  ${teachersLength}`} `}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <CustomSelectField
              label='Subject'
              id='subject'
              key={initialSubject}
              items={subjectList}
              onSelectValue={(id, newVal) => onChange(id, newVal)}
              initialValue={initialSubject}
              disabled={!subjectList?.length}
              isSearchable
              isRequired
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant='contained'
              size='medium'
              color='primary'
              type='submit'
              fullWidth
              buttonRootClass={classes.loginButtonRoot}
              disabled={disableSeedButton}
              onClick={() => {
                if (classDetails.endDate > classDetails.startDate) {
                  onSeedButtonClick()
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    errorMessage: 'End Date should be greater than Start Date',
                  }))
                }
              }}
            >
              {renderButtonName(currentTab, classDetails?.name)}
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  },
)

ClassDetail.propTypes = {
  classDetails: PropTypes.object,
  subjectList: PropTypes.array,
  initialSubject: PropTypes.object,
  disableSeedButton: PropTypes.bool,
  currentTab: PropTypes.number,
  studentsLength: PropTypes.number,
  teachersLength: PropTypes.number,
  onChange: PropTypes.func,
  onSeedButtonClick: PropTypes.func,
}

export default ClassDetail
