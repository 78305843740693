import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  uploadTitle: {
    // fontSize: '0.875rem',
    fontWeight: 500,
    margin: '0 0 4px 0',
    // color: 'rgba(0, 0, 0, 0.54)',
  },
  fileButton: {
    borderRadius: '5px',
    border: '1px solid #ebebeb',
    wordBreak: 'break-word',
    height: 'auto',
    textTransform: 'none',
    // backgroundColor: '#fbfcfc',
    marginBottom: '5px',
    width: '100%',
  },
  uploadIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '0.625rem',
    },
    // zIndex: '1',
  },
  fileTextField: {
    width: 1,
    height: 1,
    opacity: 0,
    display: 'block',
    marginLeft: 24,
  },
  confirmationAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  confirmationButtonRoot: {
    maxWidth: '8rem',
    padding: '8px 16px',
  },
  tableContent: {
    margin: '25px 0',
  },
}))

export default useStyles
