import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    fullWidth: true,
    display: 'flex',
    wrap: 'nowrap',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export default useStyles
