import { Provider } from 'react-redux'
import ThemeContextProvider from 'context/themeContext'

import Main from 'containers/Main'
import store from './configureStore'

import 'assets/App.css'

export default () => (
  <Provider store={store}>
    <ThemeContextProvider>
      <Main />
    </ThemeContextProvider>
  </Provider>
)
